import { Center } from '@mantine/core';
import { PageTitle } from 'components/PageTitle';
import { ReactElement } from 'react';

function Custom404() {
  return (
    <>
      <PageTitle>Error</PageTitle>
      <Center h="100vh">
        <h1>404 - Page Not Found</h1>
      </Center>
    </>
  );
}

Custom404.getLayout = (page: ReactElement) => page;

export default Custom404;
